import CardSet from "./CardSet";
import PopInText from './PopInText';
import ProgressBar from './ProgressBar';

export default class Seat extends Phaser.GameObjects.Container {
  constructor({scene, 
    x, 
    y, 
    text, 
    level = null, 
    imageKey, 
    seatPosition, 
    id, 
    cardSelectHandler = null,
    scale = .4,
  }) {
    super(scene, x, y);
    this.scene = scene;
    this.id = id;
    this.xPos = x;
    this.yPos = y;
    this.level = level;
    this.seatPosition = seatPosition;
    this.imageKey = imageKey;
    this.ready = false;
    this.setScale(scale);
    this.ORIGINAL_SCALE = scale;
    let highlightObj = new Phaser.GameObjects.Graphics(this.scene);
    highlightObj.setName("highlight");
    highlightObj.setAlpha(0);
    highlightObj.fillStyle(0xffffff, 1);
    highlightObj.fillCircle(this.seatPosition.highlightX, this.seatPosition.highlightY, this.seatPosition.highlightSize);
    this.add(highlightObj);
    this.avatar = scene.add.sprite(0, 0, imageKey);
    this.avatar.setScale(2);
    this.avatar.setAlpha(0);
    this.avatar.setDepth(-100000)
    this.add(this.avatar);
    this.score = 0;

    if (this.level != null) {
      const levelSize = level.toString().length;
      let levelBoxWidth = 35 + levelSize * 35;
      let levelBoxHeight = 70;
      let levelBoxX = -150 - (levelSize * 35);
      let levelBoxY = (this.avatar.height / 2) + 45;
      this.levelBox = new Phaser.GameObjects.Graphics(scene);
      this.levelBox.fillStyle(0x540016, 1); // 0xffca3a = gold, 0x540016 = maroon, 0x383838 = dark gray
      this.levelBox.fillRoundedRect(levelBoxX, levelBoxY, levelBoxWidth, levelBoxHeight, 20);
      this.levelBox.setAlpha(.7);
      this.levelBox.setDepth(-3);
      this.add(this.levelBox);

      this.levelText = new Phaser.GameObjects.Text(scene, levelBoxX + (levelBoxWidth / 2) , levelBoxY + (levelBoxHeight / 2), level, {font: '60px Arial', color: '#ffca3a', boundsAlignH: "center", boundsAlignV: "middle", align: 'center'});
      this.levelText.setShadow(3, 3, '#000000', 1, true);
      this.levelText.setOrigin(0.5);
      this.levelText.setDepth(-1);
      this.add(this.levelText);

      // per-game score
      this.scoreText = new Phaser.GameObjects.Text(scene, levelBoxX - 15, levelBoxY + (levelBoxHeight / 2), "0", {font: '96px Arial Black', color: '#ffffff', boundsAlignH: "center", boundsAlignV: "middle", align: 'center'});
      this.scoreText.setShadow(3, 3, '#000000', 1, true);
      this.scoreText.setOrigin(1,.5);
      this.add(this.scoreText);
    }

    // this.textShadow = new Phaser.GameObjects.Text(scene, -95, 165, text, {font: "64px Arial", color: "#000000", boundsAlignH: "center", boundsAlignV: "middle", align: 'center'});
    // this.add(this.textShadow);
    this.text = new Phaser.GameObjects.Text(scene, -100, 160, text, {font: "74px Arial", color: "#ffffff", boundsAlignH: "center", boundsAlignV: "middle", align: 'center'});
    this.text.setShadow(3, 3, '#000000', 1, true);
    this.add(this.text);
    this.drawPile = new CardSet({
      scene: this.scene,
      name: `${this.id}_drawPile`,
      x: this.xPos * this.seatPosition.drawPileX,
      y: this.yPos * this.seatPosition.drawPileY,
      z: this.seatPosition.drawPileZ,
      stepStack: 10,
      angle: this.seatPosition.drawPileAngle,
      angleCard: 0.02,
      angleCardRandom: 2,
      depth: -500,
      xSpread: -0.08,
      ySpread: -0.25,
    });
    this.hand = new CardSet({
      scene: this.scene,
      name: `${this.id}_hand`,
      x: this.scene.game.positionConfig.seats[this.seatPosition.position].hand.x,
      y: this.scene.game.positionConfig.seats[this.seatPosition.position].hand.y,
      z: this.seatPosition.handZ,
      depth: this.seatPosition.handDepth ? this.seatPosition.handDepth : -10000,
      angle: this.seatPosition.handAngle,
      xSpread: this.seatPosition.handXSpread,
      ySpread: this.seatPosition.handYSpread,
      xSpreadMin: 35,
      ySelectOffset: .5,
      maxWidth: this.scene.game.config.width * 0.75,
      angleCard: this.seatPosition.handAngleCard,
      cardSelectHandler: cardSelectHandler,
    });
    this.hand.seat = this;
    let readyCheck = new Phaser.GameObjects.Text(this.scene, -210, -210, '\u2B1A', {font: "108px Arial", color: "#ffffff", boundsAlignH: "center", boundsAlignV: "middle", align: 'center'});
    readyCheck.setName("readyCheck");
    this.add(readyCheck);

    this.turnTimeLimitProgressBar = new ProgressBar({scene: this.scene,
      x: -100,
      y: (this.avatar.height / 2) + 135,
      width: 230,
      height: 25,
      addToScene: false
    });
    this.add(this.turnTimeLimitProgressBar);
    this.turnTimeLimitProgressBar.setVisible(false);

    scene.add.existing(this);
  }

  setScore(score) {
    this.score = score;
    this.scoreText.setText(this.score);
  }

  addScore(score) {
    this.score += score;
    this.scoreText.setText(this.score);
  }

  showCheckMark(enable) {
    this.getByName("readyCheck").setVisible(enable);
  }

  setTimeLimit(progressObj) {
    this.turnTimeLimitProgressBar.setProgress(progressObj);
  }

  checkMark(ready) {
    this.ready = ready;
    if (ready) {
      // show green check mark relative to seat x and y
      this.getByName("readyCheck").setText('\u2705');
    } else {
      // show gray check mark relative to seat x and y
      this.getByName("readyCheck").setText('\u2B1A');
    }
  }

  highlightSeat(enable) {
    if (enable) {
      this.scene.tweens.addCounter({
        from: 70,
        to: 100,
        duration: 200,
        yoyo: true,
        onUpdate: tween => {
          if (!self.disabled) {
            let t = Math.floor(tween.getValue());
            this.getByName("highlight").setAlpha(t/100);
          }
        },
        onComplete: () => {
          this.scene.tweens.addCounter({
            from: 70,
            to: 100,
            duration: 200,
            yoyo: true,
            onUpdate: tween => {
              if (!self.disabled) {
                let t = Math.floor(tween.getValue());
                this.getByName("highlight").setAlpha(t/100);
              }
            },
          }).play();
        },
      }).play();
    } else {
      // this.getByName("highlight").setAlpha(0);
      this.scene.tweens.addCounter({
        from: 55,
        to: 0,
        duration: 400,
        // yoyo: true,
        onUpdate: tween => {
          if (!self.disabled) {
            let t = Math.floor(tween.getValue());
            this.getByName("highlight").setAlpha(t/100);
          }
        },
      }).play();
    }
  }

  occupySeat(name, avatarImageKey, showCheckMark = true, level) {
    this.text.setText(name);
    // this.textShadow.setText(name);
    this.avatar.setTexture(avatarImageKey);
    this.avatar.setAlpha(1);
    this.levelBox.setVisible(true);
    this.levelText.setVisible(true);
    this.level = level;
    this.levelText.setText(level); // TODO update level width in case it is now more characters than before (edge case tho)
    this.checkMark(!showCheckMark);
    this.showCheckMark(showCheckMark);
  }

  updateAvatar(avatarImageKey) {
    this.avatar.setTexture(avatarImageKey);
    this.avatar.setAlpha(1);
  }

  vacate() {
    this.text.setText('');
    // this.textShadow.setText('');
    this.levelBox.setVisible(false);
    this.levelText.setVisible(false);
    this.scoreText.setVisible(false);
    this.avatar.setAlpha(0);
    this.avatar.setTexture('blankavatar');
    this.showCheckMark(false);
    if (this.disconnected) {
      this.disconnected.destroy();
    }
    new PopInText({
      scene: this.scene,
      x: this.scene.game.config.width * this.seatPosition.x,
      y: this.scene.game.config.height * this.seatPosition.y,
      text: `Player\nhas left`,
      startScale: .4,
      scaleBy: .8,
      duration: 2000
    });
  }

  setPlayingPosition() {
    const self = this;
    this.scene.tweens.add({
      targets: this,
      x: this.scene.game.positionConfig.seats[this.seatPosition.position].x,
      y: this.scene.game.positionConfig.seats[this.seatPosition.position].y,
      rotation: this.seatPosition.seatPlayingRotation,
      scale: this.ORIGINAL_SCALE * this.scene.game.positionConfig.seats[this.seatPosition.position].scaleAdjustmentPlaying,
      ease: 'Expo',
      duration: 600
    }).play();
  }

  setReadyPosition() {
    const self = this;
    this.scene.tweens.add({
      targets: this,
      x: this.scene.game.positionConfig.seats[this.seatPosition.position].x,
      y: this.scene.game.positionConfig.seats[this.seatPosition.position].y,
      rotation: this.seatPosition.seatPlayingRotation,
      scale: this.ORIGINAL_SCALE * this.scene.game.positionConfig.seats[this.seatPosition.position].scaleAdjustmentReady,
      ease: 'Expo',
      duration: 600
    }).play();
  }

  static playerRightPosition = {
    position: 'right',
    x: .8,
    y: .5, 
    seatPlayingX: .86, 
    seatPlayingY: .51, 
    seatPlayingRotation: 0, // Math.PI / -2, 
    seatPlayingPortraitOffset: { fromWidth: 100, fromOriginY: 380 },
    handAngle: 0, // -90,
    handX: .86,
    handY: .43,
    handZ: -15,
    handAngleCard: 1, //-1,
    handXSpread: 15,
    handYSpread: -0.25,
    drawPileAngle: 95,
    drawPileX: 1.05,
    drawPileY: 0.6,
    drawPileZ: -20,
    highlightX: 100,
    highlightY: 0,
    highlightSize: 500,
    bonusIconX: .85,
    bonusIconY: .5,
    popInText: { x: .8, y: .5 }
  };
  static playerLeftPosition = { 
    position: 'left',
    x: .15, 
    y: .5, 
    seatPlayingX: .14, 
    seatPlayingY: .51, 
    seatPlayingRotation: 0, // Math.PI / 2, 
    seatPlayingPortraitOffset: { fromOriginX: 120, fromOriginY: 380 },
    handAngle: 0, //90,
    handX: .14,
    handY: .43,
    handZ: -15,
    handAngleCard: 1,
    handXSpread: 15,
    handYSpread: -0.25,
    drawPileAngle: 86,
    drawPileX: 0.5,
    drawPileY: 1.4,
    drawPileZ: -20,
    highlightX: -100,
    highlightY: 0,
    highlightSize: 500,
    bonusIconX: .15,
    bonusIconY: .5,
    popInText: { x: .2, y: .5 }
  };
  static playerBottomPosition = { 
    position: 'bottom',
    x: .5, 
    y: .8, 
    seatPlayingX: .5, 
    seatPlayingY: .84,
    seatPlayingPortraitOffset: { x: 0, fromHeight: 110 },
    seatPlayingRotation: 0, 
    handAngle: 0,
    handX: .5,
    handY: .97,
    handZ: 2,
    handAngleCard: 1,
    handXSpread: 35,
    handYSpread: -0.25,
    handDepth: 2000,
    drawPileAngle: 10,
    drawPileX: 1.6,
    drawPileY: 1.1,
    drawPileZ: 0,
    highlightX: 0,
    highlightY: 400,
    highlightSize: 800,
    bonusIconX: .6,
    bonusIconY: .75,
    popInText: { x: .5, y: .8 }
  };
  static playerTopPosition = { 
    position: 'top',
    x: .5, 
    y: .2, 
    seatPlayingX: .5, 
    seatPlayingY: .18, 
    seatPlayingRotation: 0,
    seatPlayingPortraitOffset: { x: 0, fromOriginY: 240 },
    handAngle: 0, // 180,
    handX: .5,
    handY: .10,
    handZ: -25,
    handAngleCard: 1, // -1,
    handXSpread: 15,
    handYSpread: -0.25,
    drawPileAngle: -10,
    drawPileX: 0.8,
    drawPileY: 0.5,
    drawPileZ: -25,
    highlightX: 0,
    highlightY: -150,
    highlightSize: 500,
    bonusIconX: .57,
    bonusIconY: .25,
    popInText: { x: .5, y: .2 }
  };
  static seatPositions = [Seat.playerTopPosition, 
    Seat.playerLeftPosition, 
    Seat.playerRightPosition];
}